@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
